import { PerLinkAttributions, wrapWithAttribution } from "@/core/parameterlessAttribution"

export type Campaign = {
  result_id: string
  extra_attribution?: PerLinkAttributions
}

export function attributeProductClicksInCampaign(element: HTMLElement, { result_id, extra_attribution }: Campaign) {
  wrapWithAttribution(element, { ref: result_id }, extra_attribution)
}
